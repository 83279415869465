<template>
  <div>
    <!--begin::Employees-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Recommanded Training</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools">
            <div class="my-2 mr-3">
              <a
                @click="redirectToAvailableTrainings()"
                class="btn btn-primary font-weight-bolder"
              >
                <span class="svg-icon svg-icon-md">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M13,9 L13,6 C9.04563815,7.48814977 6.78867438,8.99350441 5,13 L8,13 C8.7428521,12.2 9.98856336,10 13,9 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        opacity="0.3"
                      />
                      <circle fill="#000000" cx="18" cy="7.5" r="3" />
                      <circle fill="#000000" cx="6" cy="18" r="3" />
                    </g>
                  </svg>
                </span>
                Available Trainings
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <v-data-table :headers="headers" :items="training_list">
          <template v-slot:item="row">
            <tr>
              <td>{{ row.item.name }}</td>
              <td>${{ row.item.price }}</td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      small
                      v-on="on"
                      @click="enrollTraining(row.item.category_id)"
                    >
                      Learn More
                    </v-btn>
                  </template>
                  <span>Learn More</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </div>
    <div class="row mb-10">
      <div class="col-xxl-12">
        <training-in-progress :enroll_btn="true"></training-in-progress>
      </div>
    </div>
    <!--end::Employees-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import TrainingInProgress from "./TrainingInProgress.vue";

export default {
  name: "trainingList",
  vuetify: new Vuetify(),
  components: {
    TrainingInProgress
  },
  data() {
    return {
      training_list: [],
      headers: [
        { text: "Name", value: "name", width: "33%" },
        { text: "Price", value: "category_name", width: "33%" },
        { text: "Action", sortable: false, value: "action", width: "33%" }
      ],
      back_url: process.env.VUE_APP_ACBACK_URL.slice(0, -5)
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  mounted() {
    let context = this;

    // set the tab from previous
    context.$store.dispatch(SET_BREADCRUMB, [{ title: "Training" }]);
    context.getTraining();
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    enrollTraining(training_id) {
      this.$router.push({ name: "training.enroll", params: { id: training_id } });
    },
    redirectToAvailableTrainings() {
      this.$router.push({ name: "training.available" });
    },
    getTraining() {
      let context = this;
      axios({
        method: "get",
        url: "recommanded-training",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.trainings) {
            context.training_list = result.data.trainings;
          } else {
            Swal.fire(result.data.error, "", "error");
          }
        },
        function() {
          Swal.fire("Error", "Training fetch error!", "error");
        }
      );
    }
  }
};
</script>
